<template>
  <div class="multiselect-footer" :class="{ 'left-[-330px]': hasActiveResumeUpload && hasActiveMessages }">
    <p class="multiselect-footer__text font-weight-bold is-secondary-dark mb-3">
      {{ footerText }}
    </p>
    <div class="is-align-items-center gap-3">
      <BcButton
        color="error"
        icon-left="trash"
        @click="deleteProspects">
        {{ capitalize(t('campaigns.prospects.delete-selected-prospects')) }}
      </BcButton>
      <BcButton type="outlined" @click="resetSelectedCards">
        {{ t('generics.cancel') }}
      </BcButton>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import BcButton from '@/ui-kit/components/BcButton/BcButton.vue';
  import { computed, ref } from 'vue';
  import { useToast } from '@/ui-kit/components/BcToast';
  import { useStore } from '@/store';
  import { useI18n } from '@/i18n/i18n';
  import { useMutation, useQueryClient } from '@tanstack/vue-query';
  import { deleteCampaignProspect } from '@/api/campaigns';
  import { CampaignProspect } from '@/domains/models/Campaign';
  import { capitalize } from '@/utils/stringTransform';

  const store = useStore();
  const toast = useToast();
  const queryClient = useQueryClient();
  const { t, tc } = useI18n();

  const modal = ref<string>();

  const selectedCards = computed(() => store.state.card.selectedCards);
  const hasActiveResumeUpload = computed(() => store.getters['resumeHistories/hasActiveResumeUpload']);
  const hasActiveMessages = computed(() => store.getters['messageHistories/hasActiveMessages']);

  const footerText = computed(() => {
    return selectedCards.value.length > 1
      ? ` ${t('companies.account-management.selection-of')} ${selectedCards.value.length} ${tc('generics.profile', 2)} :`
      : `${t('companies.account-management.selection-of')} ${selectedCards.value.length} ${tc('generics.profile', 1)} :`;
  });

  function closeModal() {
    modal.value = undefined;
  }

  interface UpdateSelectedCardsParams {
    call: (index: number) => Promise<CampaignProspect>;
    errorMessage: string;
    successMessage: string;
    informationMessage: string;
    removeSelectedCards: (prospect: CampaignProspect) => void;
  }

  async function updateSelectedCards({
    call,
    errorMessage,
    successMessage,
    informationMessage,
    removeSelectedCards,
  }: UpdateSelectedCardsParams) {
    let count = 0;

    await store.dispatch('setIsRequestProcessing', true);

    for (const index in selectedCards.value) {
      try {
        await call(Number(index));

        if (removeSelectedCards) {
          removeSelectedCards(selectedCards.value[index]);
        }
        ++count;
      } catch (error: any) {
        toast.show({
          title: errorMessage,
          message: `${error?.response?.data?.message} - id: ${selectedCards.value[index]._id}`,
          type: 'error',
          icon: 'cross',
        });
      }
    }

    await store.dispatch('setIsRequestProcessing', false);

    closeModal();

    if (count) {
      toast.show({
        title: t('generics.success'),
        message: `${count} ${successMessage}`,
        type: 'success',
        icon: 'check',
      });
    } else {
      toast.show({
        title: tc('generics.information', 0),
        message: informationMessage,
        type: 'info',
        icon: 'info-full',
      });
    }
  }

  const deleteProspectMutation = useMutation({
    mutationKey: ['delete-prospect'],
    mutationFn: async (prospectId: string) => {
      await deleteCampaignProspect(prospectId);
      return prospectId;
    },
  });

  async function deleteProspectItem(index: number) {
    const prospect = selectedCards.value[index];
    await deleteProspectMutation.mutateAsync(prospect._id);
    return prospect;
  }

  function removeSelectedProspectFromCardsList(prospect: CampaignProspect) {
    store.dispatch('removeCard', prospect._id);
  }

  async function deleteProspects() {
    closeModal();
    await updateSelectedCards({
      call: deleteProspectItem,
      errorMessage: t('generics.resource-error-deleting', { resource: t('generics.this-prospect') }),
      successMessage: t('generics.resource-successfully-deleted', { resource: t('generics.prospects') }),
      informationMessage: t('generics.resource-unable-to-delete', { resource: t('generics.prospects') }),
      removeSelectedCards: removeSelectedProspectFromCardsList,
    });

    resetSelectedCards();
    await queryClient.invalidateQueries({ queryKey: ['prospects'] });
  }

  function resetSelectedCards() {
    store.dispatch('setSelectedCards', []);
  }
</script>

<style lang="scss" scoped>
  .multiselect-footer {
    background: linear-gradient(0deg, white 70%, transparent);
    z-index: 2;
    padding: 20px 0 20px;
  }
</style>
