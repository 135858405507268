<script lang="ts" setup>
  import CardList from '@/components/CardList/CardList.vue';
  import Card from '@/components/Card/Card.vue';
  import { useStore } from '@/store';
  import { useRouter, useRoute } from 'vue-router/composables';
  import ProspectsCard from '@/components/Prospects/ProspectsCard.vue';
  import { ref, computed } from 'vue';
  import BcSpinner from '@/ui-kit/components/BcSpinner/BcSpinner.vue';
  import { useIntersectionObserver } from '@vueuse/core';
  import { useMultiselect } from '@/composables/useMultiselect';

  const store = useStore();
  const router = useRouter();
  const route = useRoute();
  const props = defineProps<{
    cards: any[]
    hasMore: boolean
    isLoadingMore: boolean
  }>();

  const emit = defineEmits<{
    (e: 'load-more'): void
  }>();

  const cardListRef = ref<HTMLElement>();
  const loadMoreTriggerRef = ref<HTMLElement>();

  type CampaignCard = {
    _id: string;
  }

  const { selectedCards, setSelectedCards, getIsSelectedStatus } = useMultiselect();


  function goToCoder(id: string) {
    console.log('id', id);
    router.push({
      name: 'ProspectsPanel',
      params: {
        id,
      },
      query: {
        ...route.query,
        type: 'coders',
      },
    });
  }

  // Debug intersection observer
  useIntersectionObserver(
    loadMoreTriggerRef,
    ([{ isIntersecting }]) => {
      console.log('Intersection observer triggered:', { isIntersecting, hasMore: props.hasMore, isLoadingMore: props.isLoadingMore });
      if (isIntersecting && props.hasMore && !props.isLoadingMore) {
        emit('load-more');
      }
    },
    { 
      threshold: 0.1,
    }
  );
</script>

<template>
  <div class="flex flex-col">
    <CardList
      ref="cardListRef"
      :cards="props.cards"
      :is-loaded="true"
      class="min-h-[200px]">
      <template #card="{ card }">
        <Card
          :card-content="card"
          @click.native="goToCoder(card.coder._id)">
          <ProspectsCard
            :card-content="card"
            :is-selected="getIsSelectedStatus(card._id)"
            context="search-dbprospects"
            @checked="setSelectedCards($event, card)"
          >
          </ProspectsCard>
        </Card>
      </template>
    </CardList>
    <div v-if="props.isLoadingMore" class="flex justify-center py-4">
      <BcSpinner size="large" />
    </div>
    <!-- Intersection observer target -->
    <div ref="loadMoreTriggerRef" class="h-4 w-full" />
  </div>
</template>
