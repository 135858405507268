<script setup>
  import BcCheckbox from '@/ui-kit/components/BcCheckbox/BcCheckbox';
  import { useStore } from '@/store.js';
  import GridSortSelect from '@/components/Grid/GridSortSelect.vue';

  // Props
  defineProps({
    isChecked: {
      type: Boolean,
      default: false,
    },
    showArrivalDate: {
      type: Boolean,
      default: false,
    },
  });

  // Store
  const store = useStore();

  // Methods
  const handleSort = async(field, sortOption) => {
    await store.dispatch('setSort', {
      field,
      direction: sortOption.value,
    });
  };
</script>

<template>
  <div class="prospects-grid w-full">
    <div class="prospects-grid__container !text-blue-400 !text-sm uppercase grid grid-cols-[40px_minmax(200px,2fr)_120px_60px_minmax(100px,1fr)_minmax(160px,1fr)_minmax(120px,2fr)_80px] gap-x-2.5 text-xs w-full px-[30px] py-[25px]">
      <BcCheckbox
        id="checkbox"
        :value="isChecked"
        class="prospects-grid__text !ml-0 items-start invisible"
        @input="$emit('checked', $event)"
      />
      <grid-sort-select
        :label="`${$t('generics.identity')}, ${$t('avatar-dropdown.job-title')}`"
        class="prospects-grid__text"
        @sort="handleSort('firstName', $event)"
      />
      <p class="prospects-grid__text">
        {{ $t('generics.coordinates') }}
      </p>
      <p class="prospects-grid__text">
        {{ $t('companies.account-management.in-cv-upper') }}
      </p>
      <grid-sort-select
        :label="`${$t('companies.account-management.locations-upper')}`"
        class="prospects-grid__text"
        @sort="handleSort('location', $event)"
      />
      <p class="prospects-grid__text">
        {{ $t('generics.step') }}
      </p>
      <p class="prospects-grid__text">
        {{ $t('hunt.last-activity') }}
      </p>
      <p class="prospects-grid__text">
        sources
      </p>
    </div>
  </div>
</template>

<style lang="scss">
  .prospects-grid {
    &__checkbox {
      background: $color-white;
    }

  }
</style>
