<script lang="ts" setup>
import { computed, ref, ComputedRef } from 'vue';
import BcCheckbox from '@/ui-kit/components/BcCheckbox/BcCheckbox.vue';
import { useI18n } from '@/i18n/i18n';
import Tag from '@/components/Tag/Tag.vue';
import { CampaignProspect, CampaignActionKey, CampaignConditionKey, CampaignProspectActionStatus } from '@/domains/models/Campaign';
import BcAvatar from '@/ui-kit/components/BcAvatar/BcAvatar.vue';
import CardTags from '@/components/Card/CardTags.vue';
import { formatPlaceDetailsToBaseLocation } from '@/formatters/locations';
import moment from 'moment/min/moment-with-locales';
import ProspectStepStatus from './ProspectStepStatus.vue';
import { addProspectActionLabels } from '@/utils/campaignActions';

const props = defineProps<{
  cardContent: CampaignProspect
  isSelected: boolean
  context: String
}>();

const { t, tc } = useI18n();

defineEmits(['checked']);

const actionStatusTranslations: Record<CampaignProspectActionStatus, Record<string, string>> = {
  ONGOING: {
    LINKEDIN_MESSAGE: 'campaigns.next-action.awaiting-linkedin-message-send',
    LINKEDIN_INMAIL_SALES_NAVIGATOR: 'campaigns.next-action.awaiting-inmail-send',
    LINKEDIN_INMAIL_RECRUITER: 'campaigns.next-action.awaiting-inmail-send',
    LINKEDIN_INMAIL_PREMIUM: 'campaigns.next-action.awaiting-inmail-send',
    LINKEDIN_INVITATION: 'campaigns.next-action.awaiting-invitation-send',
    LINKEDIN_PROFILE_VISIT: 'campaigns.next-action.awaiting-profile-visit-send',
    LINKEDIN_VOICE_NOTE: 'campaigns.next-action.awaiting-voice-message-send',
    SEND_MAIL: 'campaigns.next-action.awaiting-email-send'
  },
  EXECUTED: {
    LINKEDIN_MESSAGE: 'campaigns.activity.actions.linkedin-message-sent',
    LINKEDIN_INMAIL_SALES_NAVIGATOR: 'campaigns.activity.actions.inmail-sent',
    LINKEDIN_INMAIL_RECRUITER: 'campaigns.activity.actions.inmail-sent',
    LINKEDIN_INMAIL_PREMIUM: 'campaigns.activity.actions.inmail-sent',
    LINKEDIN_INVITATION: 'campaigns.activity.actions.invitation-sent',
    LINKEDIN_PROFILE_VISIT: 'campaigns.activity.actions.profile-visit-sent',
    LINKEDIN_VOICE_NOTE: 'campaigns.activity.actions.voice-message-sent',
    SEND_MAIL: 'campaigns.activity.actions.email-sent'
  },
  FAILED: {
    LINKEDIN_MESSAGE: 'campaigns.next-action.sending-linkedin-message-failed',
    LINKEDIN_INMAIL_SALES_NAVIGATOR: 'campaigns.next-action.sending-inmail-failed',
    LINKEDIN_INMAIL_RECRUITER: 'campaigns.next-action.sending-inmail-failed',
    LINKEDIN_INMAIL_PREMIUM: 'campaigns.next-action.sending-inmail-failed',
    LINKEDIN_INVITATION: 'campaigns.next-action.sending-invitation-failed',
    LINKEDIN_PROFILE_VISIT: 'campaigns.next-action.sending-profile-visit-failed',
    LINKEDIN_VOICE_NOTE: 'campaigns.next-action.sending-voice-message-failed',
    SEND_MAIL: 'campaigns.next-action.sending-email-failed'
  },
  PENDING: {},
  COMPLETED: {}
};

const isHover = ref(false);

// const userInitials = computed(() => {
//   return `${props.cardContent.coder.firstName?.charAt(0)}${props.cardContent.coder.lastName?.charAt(0)}`;
// });
const contactInfos = computed(() => {
  const phones = props.cardContent.coder.phones ? props.cardContent.coder.phones.map(phone => ({
    name: phone,
    type: 'phone',
  })) : [];
  const emails = props.cardContent.coder.emails ? props.cardContent.coder.emails.map(email => ({
    name: email,
    type: 'mail',
  })) : [];

  return [
    { name: props.cardContent.coder.phone, type: 'phone' },
    { name: props.cardContent.coder.email, type: 'mail' },
    ...phones,
    ...emails,
  ].filter(e => e).filter(contactInfo => !!contactInfo.name);
});

function setAlternativeImg(event: Event) {
  const target = event.target as HTMLImageElement;
  target.src = require('@/assets/img/default-avatar.svg');
}

const locations = computed(() =>
  props.cardContent.coder.locations?.map(formatPlaceDetailsToBaseLocation) || [],
);

const currentProspectAction = computed(() => {
  return props.cardContent.prospectActions.find(action => action._campaignAction === props.cardContent._currentAction);
});

const isCurrentActionExecuted = computed(() => {
  return currentProspectAction.value?.executedAt;
});

// get prospect action preceding the current prospect action
const previousProspectAction = computed(() => {
  const actions = props.cardContent.prospectActions;
  const currentIndex = actions.findIndex(action => action._campaignAction === props.cardContent._currentAction);

  if (currentIndex > 0) {
    return actions[currentIndex - 1];
  }
  return undefined;
});

const lastActivityTriggerExceptDelay = computed(() => {
  return previousProspectAction.value?.triggered?.find(trigger => trigger.conditionType && trigger.conditionType !== 'DELAY');
});

const lastActivityType = computed(() => {
  if (isCurrentActionExecuted.value) {
    return currentProspectAction.value?.campaignAction?.type;
  }
  return lastActivityTriggerExceptDelay.value?.conditionType ?? previousProspectAction.value?.campaignAction?.type;
}) as ComputedRef<CampaignActionKey | CampaignConditionKey | undefined>;

const lastActivityDate = computed(() => {
  if (isCurrentActionExecuted.value) {
    return moment(props.cardContent.prospectActions.find(action => action.status === 'ONGOING')?.executedAt).fromNow();
  }
  if (lastActivityTriggerExceptDelay.value) {
    return moment(lastActivityTriggerExceptDelay.value?.triggeredAt).fromNow();
  }
  return moment(previousProspectAction.value?.executedAt).fromNow();
});

const activityTypeTranslations: Record<CampaignActionKey | CampaignConditionKey, string> = {
  // actions
  SEND_MAIL: 'campaigns.activity.actions.email-sent',
  LINKEDIN_MESSAGE: 'campaigns.activity.actions.linkedin-message-sent',
  LINKEDIN_INMAIL_SALES_NAVIGATOR: 'campaigns.activity.actions.inmail-sent',
  LINKEDIN_INMAIL_RECRUITER: 'campaigns.activity.actions.inmail-sent',
  LINKEDIN_INMAIL_PREMIUM: 'campaigns.activity.actions.inmail-sent',
  LINKEDIN_INVITATION: 'campaigns.activity.actions.invitation-sent',
  LINKEDIN_PROFILE_VISIT: 'campaigns.activity.actions.profile-visited',
  LINKEDIN_VOICE_NOTE: 'campaigns.activity.actions.voice-message-sent',
  // triggers
  INVITATION_ACCEPTED: 'campaigns.activity.triggers.invitation-accepted',
  PROFILE_VISITED: 'campaigns.activity.triggers.profile-has-been-viewed',
  MAIL_ANSWER: 'campaigns.activity.triggers.mail-answer',
  DELAY: 'campaigns.triggers.delay',
  PROCESSED_CANDIDATE: 'campaigns.activity.processed-candidate',
  IMMEDIATELY_AFTER_STEP: 'campaigns.activity.immediately-after-step'
};

const stepNameTranslations: Record<CampaignActionKey, string> = {
  SEND_MAIL: 'campaigns.steps.send-email',
  LINKEDIN_MESSAGE: 'campaigns.steps.linkedin-message',
  LINKEDIN_INMAIL_SALES_NAVIGATOR: 'campaigns.steps.linkedin-inmail-sales-navigator',
  LINKEDIN_INMAIL_RECRUITER: 'campaigns.steps.linkedin-inmail-recruiter',
  LINKEDIN_INMAIL_PREMIUM: 'campaigns.steps.linkedin-inmail-premium',
  LINKEDIN_INVITATION: 'campaigns.steps.linkedin-invitation',
  LINKEDIN_PROFILE_VISIT: 'campaigns.steps.linkedin-profile-visit',
  LINKEDIN_VOICE_NOTE: 'campaigns.steps.linkedin-voice-note'
};

const i18NActivityType = computed(() => {
  if (!lastActivityType.value) return '';

  return t(activityTypeTranslations[lastActivityType.value] || lastActivityType.value);
});

const lastActivityDateWithAction = computed(() => {
  if (lastActivityType.value && lastActivityDate.value) {
    return `${i18NActivityType.value} ${lastActivityDate.value}`;
  }
  return '';
});

const currentStepName = computed(() => {
  if (props.cardContent.status === 'FINISHED') {
    return t('campaigns.next-action.finished');
  }
  if (!props.cardContent._currentAction) {
    return t('campaigns.next-action.in-preparation');
  }

  const labeledActions = addProspectActionLabels(props.cardContent.prospectActions, (campaignAction) => {
    return t(stepNameTranslations[campaignAction.type] || '');
  });

  const currentAction = labeledActions.find(pA => pA._campaignAction === props.cardContent._currentAction);
  return currentAction?.campaignAction.label || '';
});

const stepStatus = computed(() => {
  if (props.cardContent.status === 'FINISHED') {
    return {
      validationIcon: { icon: 'check', color: 'text-green-500' },
      text: t('campaigns.next-action.finished')
    };
  }

  if (!props.cardContent._currentAction) {
    return {
      executionIcon: { icon: 'play', color: 'text-gray-400' },
      text: t('campaigns.next-action.retrieving-information')
    };
  }

  const action = currentProspectAction.value;
  if (!action) return {};

  if (action.failedAt) {
    if (action.executedAt) {
      return {
        executionIcon: { icon: 'play', color: 'text-green-500' },
        validationIcon: { icon: 'cross', color: 'text-red-500' },
        text: `${t('campaigns.next-action.validation-failed')} ${moment(action.failedAt).fromNow()}`
      };
    }
    return {
      executionIcon: { icon: 'cross', color: 'text-red-500' },
      text: `${t((actionStatusTranslations.FAILED as Record<string, string>)[action.campaignAction.type] || '')}${moment(action.failedAt).fromNow()}`
    };
  }

  if (action.executedAt) {
    return {
      executionIcon: { icon: 'play', color: 'text-green-500' },
      validationIcon: { icon: 'check', color: 'text-gray-400' },
      text: `Exécuté - ${t('campaigns.next-action.awaiting-validation')}`
    };
  }

  if (action.status === 'ONGOING') {
    return {
      executionIcon: { icon: 'play', color: 'text-gray-400' },
      text: t((actionStatusTranslations.ONGOING as Record<string, string>)[action.campaignAction.type] || '')
    };
  }

  if (action.status === 'PENDING') {
    return {
      executionIcon: { icon: 'play', color: 'text-gray-400' },
      validationIcon: { icon: 'check', color: 'text-gray-400' },
      text: t('campaigns.next-action.pending')
    };
  }

  return {
    executionIcon: { icon: 'play-circle', color: 'text-gray-400' },
    validationIcon: { icon: 'check', color: 'text-gray-400' },
    text: ''
  };
});

const sourcesTooltipText = computed(() => {
  return props.cardContent.prospectSources.map(source => {
    if (source.type === "MANUALLY_ADDED") return t('campaigns.sources.manually-added');
    return source.name
  }).join(', ');
});
</script>

<template>
  <div class="prospects-card w-full border-radius-m is-relative px-[30px] py-[20px]" @mouseenter="isHover = true"
    @mouseleave="isHover = false">
    <div
      class="prospects-card__container grid grid-cols-[40px_minmax(200px,2fr)_120px_60px_minmax(100px,1fr)_minmax(160px,1fr)_minmax(120px,2fr)_80px] gap-x-2.5 w-full items-center">
      <div class="prospects-card__checkbox" @click.stop>
        <bc-checkbox :id="cardContent._id" :value="isSelected" @input="$emit('checked', $event)" />
      </div>

      <!-- Name and Title -->
      <div class="prospects-card__name flex items-center gap-[10px] text-secondary-dark min-w-0">
        <bc-avatar :src="cardContent.coder.pictureUrl" class="card-coders__avatar shrink-0" size="s"
          @error="setAlternativeImg">
        </bc-avatar>
        <div class="flex flex-col items-start min-w-0">
          <p :v-tooltip="`${cardContent.coder.firstName} ${cardContent.coder.lastName}`"
            class="card-coders__text body-l--medium truncate w-full">
            {{ `${cardContent.coder.firstName} ${cardContent.coder.lastName}` }}
          </p>
          <p v-if="cardContent.coder.title" v-tooltip="cardContent.coder.title"
            class="card-coders__text body-l truncate w-full">
            {{ cardContent.coder.title }}
          </p>
        </div>
      </div>

      <div class="flex shrink-0 justify-start gap-2.5 min-w-0">
        <card-tags :tags="contactInfos" class="card-coders__tag-wrapper" reference="contact-info">
          <template #tag="{ tag }">
            <tag v-tooltip="{ content: tag.name }" :icon="tag.type" text="1" />
          </template>
          <template #remaining="{ remaining }">
            <tag :show-icon="!!contactInfos?.[1]?.type" :icon="contactInfos?.[1]?.type" :text="`${remaining}`" />
          </template>
        </card-tags>
      </div>

      <div class="flex shrink-0 justify-start">
        <img alt="logo linkedin" class="card-coders__icon" src="@/assets/logo/logo_linkedin.svg" />
      </div>

      <div class="flex shrink-0 justify-start min-w-0">
        <card-tags :tags="locations" class="card-coders__tag-wrapper" reference="locations">
          <template #tag="{ tag }">
            <tag v-tooltip="{ content: [...new Set([tag.fullAddress])].join(', ') }" :text="tag.fullAddress"
              :text-limit="18" type="location">
            </tag>
          </template>
          <template #remaining="{ remaining }">
            <tag :show-icon="false" :text="`+${remaining}`" type="location"></tag>
          </template>
        </card-tags>
      </div>

      <div class="flex justify-start min-w-0">
        <p v-tooltip="stepStatus.text"
          class="flex min-w-0 rounded-2xl bg-blue-100 px-2.5 h-5 text-xs text-blue-800 truncate items-center gap-1">
          <span class="truncate flex items-center mr-1">{{ currentStepName }}</span>
          <span v-if="stepStatus.executionIcon"
            :class="[stepStatus.executionIcon.color, `icon-${stepStatus.executionIcon.icon}`, 'flex items-center shrink-0']"></span>
          <span v-if="stepStatus.validationIcon"
            :class="[stepStatus.validationIcon.color, `icon-${stepStatus.validationIcon.icon}`, 'flex items-center shrink-0']"></span>
        </p>
      </div>

      <div class="flex flex-row justify-start">
        <p v-if="lastActivityDateWithAction"
          class="flex items-center rounded-2xl bg-blue-100 px-2.5 h-5 text-xs text-blue-800 min-w-0 max-w-full"
          v-tooltip="lastActivityDateWithAction">
          <span class="truncate">{{ lastActivityDateWithAction }}</span>
        </p>
      </div>

      <div class="flex justify-start gap-2 min-w-0">
        <tag v-tooltip="{ content: sourcesTooltipText }"
          :text="tc('campaigns.n-sources', props.cardContent.prospectSources.length)" :text-limit="18">
        </tag>
      </div>
    </div>
  </div>
</template>
